export default {
  name: 'ViewTransaction',
  data() {
    return {
      compositeCheck: false,
      billHdrId: null,
      loader: false,
      distributionDetails: {},
      distributionList: [],
      distributionFeilds: [
        {
          key: 'charge_type'
        },
        {
          key: 'ccid',
          label: 'CCID'
        },
        {
          key: 'gl_date',
          class: 'text-center'
        },
        {
          key: 'period'
        },
        {
          key: 'accounting_status',
          label: 'Accounting'
        },
        {
          key: 'posting_status',
          label: 'Posting'
        },
        {
          key: 'trx_amount_dr',
          label: 'Trx Amt DR',
          class: 'text-right'
        },
        {
          key: 'trx_amount_cr',
          label: 'Trx Amt CR',
          class: 'text-right'
        },
        {
          key: 'amount_dr',
          class: 'text-right'
        },
        {
          key: 'amount_cr',
          class: 'text-right'
        },
        {
          key: 'narration',
          class: 'text-right'
        }
      ],
      transactionDetails: {},
      transactionLineList: [],
      transactionLineFields: [
        {
          key: 'trx_dtl_id',
          class: 'd-none'
        },
        {
          key: 'detail'
        },
        {
          key: 'line_num'
        },
        {
          key: 'line_type'
        },
        {
          key: 'component_group',
          label: 'Comp Grp'
        },
        {
          key: 'component'
        },
        {
          key: 'trx_amount_dr',
          label: 'Trx Amt DR',
          class: 'text-right'
        },
        {
          key: 'trx_amount_cr',
          label: 'Trx Amt CR',
          class: 'text-right'
        },
        {
          key: 'amount_dr',
          label: 'Amt DR',
          class: 'text-right'
        },
        {
          key: 'amount_cr',
          label: 'Amt CR',
          class: 'text-right'
        },
        {
          key: 'dtl_narration',
          label: 'Narration',
          class: 'text-right'
        }
      ],
      lineInnerDetailsList: [],
      lineInnderDetailsFeilds: [
        {
          key: 'charge_type'
        },
        {
          key: 'ccid',
          label: 'CCID'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'hdr_period',
          label: 'Period'
        },
        {
          key: 'accounting_status',
          label: 'Accounting'
        },
        {
          key: 'posting_status',
          label: 'Posting'
        },
        {
          key: 'trx_amount_dr',
          label: 'Trx Amt DR'
        },
        {
          key: 'trx_amount_cr',
          label: 'Trx Amt CR'
        },
        {
          key: 'amount_dr'
        },
        {
          key: 'amount_cr'
        },
        {
          key: 'narration'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$on('getTransactionDetails', transactionDetails => {
      this.billHdrId = transactionDetails.trx_hdr_id;
      this.getTransactionLine(transactionDetails.trx_hdr_id);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.billHdrId });
        }
      }
    });
  },
  methods: {
    getTransactionLine(trxHdrId) {
      this.loader = true;
      this.$store
        .dispatch('fms/getFMSTransactionLineDetails', trxHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const trxDetails = response.data.data.transaction_details.map(
              detail => {
                detail.lineInnerDetailsList = [];
                return detail;
              }
            );
            this.transactionLineList = trxDetails;
            this.transactionDetails = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDistribution() {
      const payload = {
        bill_hdr_id: this.billHdrId,
        composite: false
      };
      this.$store.dispatch('fms/getFMSDistribution', payload).then(response => {
        if (response.status === 200) {
          this.distributionDetails = response.data.data;
          this.distributionList = response.data.data.trx_distributions;
        }
      });
    },
    getLineDetailsById(trxDtlId, index) {
      if (this.transactionLineList[index].lineInnerDetailsList.length === 0) {
        this.loader = true;
        this.$store
          .dispatch('fms/getFMSTransactionLineDataByDtlId', trxDtlId)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.transactionLineList[index].lineInnerDetailsList =
                response.data.data;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    }
  },
  destroyed() {
    this.unsubscribe();
    this.eventBus.$off('getTransactionDetails');
  }
};
