import commonHelper from '@/app/utility/common.helper.utility';
import AdvanceSearch from '../advanceSearch';
import ViewTransaction from './viewTransaction';
export default {
  name: 'FmsTransaction',
  components: {
    AdvanceSearch,
    ViewTransaction
  },
  watch: {
    currentPage: function() {
      this.searchParameter.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.searchBill(this.searchParameter);
    },
    perPage: function() {
      this.currentPage = 1;
      this.searchParameter.pagination = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.searchBill(this.searchParameter);
    }
  },
  data() {
    return {
      loader: false,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      isAdvanceSearchEnable: false,
      showViewTransaction: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      transactionList: [],
      transactionFields: [
        {
          key: 'trx_num'
        },
        {
          key: 'bill_num'
        },
        {
          key: 'prj_name'
        },
        {
          key: 'sub_unit_name'
        },
        {
          key: 'customer'
        },
        {
          key: 'site_name'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'trx_date'
        },
        {
          key: 'value_date',
          label: 'VL Date'
        },
        {
          key: 'source_name'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate',
          label: 'Exc Rate'
        },
        {
          key: 'trx_amount',
          label: 'Trx Amt'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showViewTransaction &&
          !this.isAdvanceSearchEnable
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.searchParameter };
          downloadpayload.pagination._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fms/getFMSTransaction',
            'Transaction',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    searchTransaction() {},
    advanceSearch() {
      this.isAdvanceSearchEnable = true;
    },
    searchBill(payload) {
      if (payload) {
        this.searchParameter = payload;
      } else {
        this.searchParameter = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          },
          with_unit: true
        };
      }
      this.loader = true;
      this.isAdvanceSearchEnable = false;
      this.$store
        .dispatch('fms/getFMSTransaction', this.searchParameter)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.transactionList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(items) {
      this.showViewTransaction = true;
      setTimeout(() => {
        this.eventBus.$emit('getTransactionDetails', items);
      }, 0);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
