import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store';
import Datepicker from 'vuejs-datepicker';
import Currency from '../../../admin/currency';
import { format } from 'date-fns';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'advanceSearch',
  components: {
    ModelSelect,
    BasicSelect,
    Datepicker,
    Currency,
    DatePicker
  },
  watch: {
    selectedProject: function() {
      if (this.selectedProject.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.selectedProject.value;
        this.getOtherLovByProjectId();
      }
    },
    selectedLeagalEntity: function() {
      this.getProjectList(this.selectedLeagalEntity.value);
      this.getCustomer(this.selectedLeagalEntity.value);
    },
    selectedBillRequest: function() {
      this.getFmsBillTemplate(this.selectedBillRequest.value);
    }
  },
  data() {
    return {
      customerCurrentPage: 1,
      showCustomerModal: false,
      customerTotalRows: null,
      customerName: null,
      customerPerPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      openCurrencyModal: false,
      defaultSelect: {
        value: null,
        text: null
      },
      defaultList: [],
      loader: false,
      lovFieldName: '',
      lovId: '',
      keyValue: {
        value: null,
        text: null
      },
      advanceSearchForm: {
        leagalEntity: [
          {
            value: null,
            text: '--select--'
          }
        ],
        orgUnit: [],
        receiptAgainst: [
          {
            value: null,
            text: null
          }
        ],
        billingStatusList: [],
        paymentMode: [
          {
            value: null,
            text: null
          }
        ],
        drawnOn: [
          {
            value: null,
            text: null
          }
        ],
        projectList: [
          {
            value: null,
            text: null
          }
        ],
        sectorList: [
          {
            value: null,
            text: null
          }
        ],
        pocketList: [
          {
            value: null,
            text: null
          }
        ],
        towerList: [
          {
            value: null,
            text: null
          }
        ],
        floorList: [
          {
            value: null,
            text: null
          }
        ],
        unitList: [
          {
            value: null,
            text: null
          }
        ],
        subUnitList: [
          {
            value: null,
            text: null
          }
        ],
        instrumentStatusList: [
          {
            value: null,
            text: null
          }
        ],
        accountNumList: [
          {
            value: null,
            text: null
          }
        ],
        sourceList: []
      },
      documentNumber: '',
      selectedReceiptAgainst: {
        value: null,
        text: null
      },
      selectedBillingStatus: {
        value: null,
        text: null
      },
      selectedLeagalEntity: {
        value: null,
        text: null
      },
      selectedOrgUnit: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      receiptNum: '',
      selectedDrawnOn: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedSector: {
        value: null,
        text: null
      },
      selectedPocket: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      selectedSource: {
        value: null,
        text: null
      },
      billingCycleList: [],
      selectedBillingCycle: {
        value: null,
        text: null
      },
      billRequestList: [],
      selectedBillRequest: {
        value: 0,
        text: null
      },
      billTemplateList: [],
      selectedBillTemplate: {
        value: 0,
        text: null
      },
      selectedCustomer: {
        name: null,
        customerId: 0,
        siteName: null,
        siteId: 0
      },
      currency: {
        name: '',
        id: 0
      },
      bpDate: null,
      dueDate: null,
      glDate: null,
      billDate: null,
      amountFrom: '',
      amountTo: '',
      customerList: [],
      customerFields: [
        {
          key: 'site_name'
        },
        {
          key: 'customer_site_id',
          class: 'd-none'
        },
        {
          key: 'customer_id',
          class: 'd-none'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'complete_address'
        },
        {
          key: 'address_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.getReceiptAgainast();
    this.getLegalEntity();
    this.getBillingStatus();
    this.getSourceList();
    this.getBillingCycle();
    this.getFmsBillRequest();
    this.eventBus.$on('getCurrency', getCurrency => {
      this.currency.name = getCurrency.currency;
      this.currency.id = getCurrency.currency_id;
      this.openCurrencyModal = false;
    });
  },
  methods: {
    selectedTowerFun(towerVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
      this.lovId = towerVal.value;
      this.selectedTower = towerVal;
      this.getOtherLovByProjectId();
    },
    selectedFloorFun(floorVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
      this.selectedFloor = floorVal;
      this.lovId = floorVal.value;
      this.getOtherLovByProjectId();
    },
    selectedUnitFun(unitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSUNIT;
      this.selectedUnit = unitVal;
      this.lovId = unitVal.value;
      this.getOtherLovByProjectId();
    },
    selectedSubUnitFun(subUnitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSSUBUNIT;
      this.selectedSubUnit = subUnitVal;
      this.lovId = subUnitVal.value;
      this.getOtherLovByProjectId();
    },
    getSourceList() {
      this.$store.dispatch('fms/getSource').then(response => {
        if (response.status === 200) {
          const result = response.data.data;
          const valueTypeSet = result.map(type => {
            return {
              value: type.source_hdr_id,
              text: type.source_name
            };
          });
          this.advanceSearchForm.sourceList = valueTypeSet;
        }
      });
    },
    searchFmsBilling() {
      const payload = {
        address: '',
        amount_from: this.amountFrom,
        amount_to: this.amountTo,
        bill_cycle_hdr_id: this.selectedBillingCycle.value,
        bill_end_date: this.billDate
          ? format(this.billDate[1], appStrings.DATEFNSFORMAT)
          : null,
        bill_start_date: this.billDate
          ? format(this.billDate[0], appStrings.DATEFNSFORMAT)
          : null,
        bill_status_vset: this.selectedBillingStatus.value,
        billing_cycle_hdr_id: null,
        bp_end_date: this.bpDate
          ? format(this.bpDate[1], appStrings.DATEFNSFORMAT)
          : null,
        bp_start_date: this.bpDate
          ? format(this.bpDate[0], appStrings.DATEFNSFORMAT)
          : null,
        currency_id: this.currency.id ? this.currency.id : null,
        customer_id: this.selectedCustomer.customerId
          ? this.selectedCustomer.customerId
          : null,
        customer_site_id: this.selectedCustomer.siteId
          ? this.selectedCustomer.siteId
          : null,
        document_num: this.documentNumber,
        due_end_date: this.dueDate
          ? format(this.dueDate[1], appStrings.DATEFNSFORMAT)
          : null,
        due_start_date: this.dueDate
          ? format(this.dueDate[0], appStrings.DATEFNSFORMAT)
          : null,
        floor_id: this.selectedFloor.value,
        gl_end_date: this.glDate
          ? format(this.glDate[1], appStrings.DATEFNSFORMAT)
          : null,
        gl_start_date: this.glDate
          ? format(this.glDate[0], appStrings.DATEFNSFORMAT)
          : null,
        pocket_id: this.selectedPocket.value,
        prj_id: this.selectedProject.value,
        request_id: this.selectedBillRequest.value,
        sector_id: this.selectedSector.value,
        source_hdr_id: this.selectedSource.value,
        sub_unit_id: this.selectedSubUnit.value,
        template_id: this.selectedBillTemplate.value,
        tower_id: this.selectedTower.value,
        unit_id: this.selectedUnit.value,
        with_unit:
          this.selectedReceiptAgainst.value === 'SUBUNIT' ? true : false,
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      this.$emit('searchFmsPayload', payload);
    },
    getBillingCycle() {
      this.$store.dispatch('fms/getBillingCycle').then(response => {
        if (response.status === 200) {
          const result = response.data.data;
          const valueTypeSet = result.map(type => {
            return {
              value: type.billing_cycle_hdr_id,
              text: type.billing_cycle_name
            };
          });
          this.billingCycleList = valueTypeSet;
          this.selectedBillingCycle =
            valueTypeSet && valueTypeSet.length === 1
              ? valueTypeSet
              : this.billingCycleList;
        }
      });
    },
    getFmsBillRequest() {
      const payload = {
        menu_id: store.state.shared.menuId
      };
      this.$store
        .dispatch('fms/getFmsBillRequest', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const valueTypeSet = result.map(type => {
              return {
                value: type.request_id,
                text: type.request_name
              };
            });
            this.billRequestList = valueTypeSet;
          }
        })
        .catch(() => {});
    },
    getFmsBillTemplate(requestId) {
      const payload = {
        request_id: requestId
      };
      this.$store
        .dispatch('fms/getFMSBillingTemplate', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const valueTypeSet = result.map(type => {
              return {
                value: type.template_id,
                text: type.template_name
              };
            });
            this.billTemplateList = valueTypeSet;
          }
        });
    },
    getOrganizationList(leId) {
      this.loader = true;
      try {
        const queryParams = {
          _page: 0,
          _limit: this.perPage,
          org_type: 'OU',
          user_id: store.state.auth.userId,
          responsibility_id: store.state.shared.responsibilityId,
          org_parent_id: leId
        };
        this.$store
          .dispatch('organization/getOrganizationList', queryParams)
          .then(response => {
            this.loader = false;
            if (response.data.data.page) {
              const result = response.data.data.page;
              const organization = result.map(type => {
                return {
                  value: type.org_id,
                  text: type.org_name
                };
              });
              this.advanceSearchForm.orgUnit = organization;
              this.selectedOrgUnit =
                organization && organization.length
                  ? organization[0]
                  : this.selectedOrgUnit;
            }
          });
      } catch (err) {
        this.err = err;
      }
    },
    getReceiptAgainast() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'FMS_SEARCH_BY')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.receiptAgainst = getValueAndText;
            this.selectedReceiptAgainst =
              getValueAndText && getValueAndText.length
                ? getValueAndText[0]
                : this.selectedReceiptAgainst;
          }
        });
    },
    getBillingStatus() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'BILL_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.billingStatusList = getValueAndText;
          }
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store.dispatch('receipt/getOrganizationLov', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const leagalEntity = results.map(type => {
            return {
              value: type.org_id,
              text: type.org_name
            };
          });
          this.advanceSearchForm.leagalEntity = leagalEntity;
          this.selectedLeagalEntity =
            leagalEntity && leagalEntity.length
              ? leagalEntity[0]
              : this.selectedLeagalEntity;
          this.getProjectList(this.selectedLeagalEntity.value);
          this.getOrganizationList(this.selectedLeagalEntity.value);
          this.getCustomer(this.selectedLeagalEntity.value);
        }
      });
    },
    getProjectList(leId) {
      const payload = {
        typeOfProject: 'FMSPRJ',
        le_id: leId
      };
      this.$store.dispatch('fms/getProjectList', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.proj_id,
              text: type.proj_name
            };
          });
          this.advanceSearchForm.projectList = getValueAndText;
        }
      });
    },
    getOtherLovByProjectId() {
      this.loader = true;
      const payload = {
        lovFieldName: this.lovFieldName,
        lovId: this.lovId
      };
      this.loader = true;
      this.$store
        .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
        .then(response => {
          this.loader = false;
          if (response && response.data && response.data.data) {
            const results = response.data.data;
            if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
              this.getSectorList(results.sectors);
              this.getPocketList(results.pockets);
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
              this.getTowerList(results.towers);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSSUBUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getSectorList(sectorList) {
      if (sectorList && sectorList.length) {
        const valueAndTextArr = sectorList.map(type => {
          return {
            value: type.sector_id,
            text: type.sector_name
          };
        });
        this.advanceSearchForm.sectorList = valueAndTextArr;
        this.selectedSector =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedSector = this.keyValue;
        this.advanceSearchForm.sectorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getPocketList(pocketList) {
      if (pocketList && pocketList.length) {
        const valueAndTextArr = pocketList.map(type => {
          return {
            value: type.pocket_id,
            text: type.pocket_name
          };
        });
        this.advanceSearchForm.pocketList = valueAndTextArr;
        this.selectedPocket =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedPocket = this.keyValue;
        this.advanceSearchForm.pocketList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getTowerList(towerList) {
      if (towerList && towerList.length) {
        const valueAndTextArr = towerList.map(type => {
          return {
            value: type.tower_id,
            text: type.tower_name
          };
        });
        this.advanceSearchForm.towerList = valueAndTextArr;
        this.selectedTower =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedTower = this.keyValue;
        this.advanceSearchForm.towerList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getFloorList(floorList) {
      if (floorList && floorList.length) {
        const valueAndTextArr = floorList.map(type => {
          return {
            value: type.floor_id,
            text: type.floor_name
          };
        });
        this.advanceSearchForm.floorList = valueAndTextArr;
        this.selectedFloor =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedFloor = this.keyValue;
        this.advanceSearchForm.floorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getUnitList(unitList) {
      if (unitList && unitList.length) {
        const valueAndTextArr = unitList.map(type => {
          return {
            value: type.unit_id,
            text: type.unit_name
          };
        });
        this.advanceSearchForm.unitList = valueAndTextArr;
        this.selectedUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedUnit = this.keyValue;
        this.advanceSearchForm.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getSubUnitList(subUnitList) {
      if (subUnitList && subUnitList.length) {
        const valueAndTextArr = subUnitList.map(type => {
          return {
            value: type.sub_unit_id,
            text: type.sun_unit_name
          };
        });
        this.advanceSearchForm.subUnitList = valueAndTextArr;
        this.selectedSubUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedSector = this.keyValue;
        this.advanceSearchForm.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getCustomer(leId) {
      const payload = {
        _page: this.customerCurrentPage - 1,
        _limit: this.customerPerPage,
        le_id: leId ? leId : null,
        customer_name: this.customerName
      };
      this.$store.dispatch('fms/getCustomerDetail', payload).then(response => {
        if (response.status === 200) {
          const result = response.data.data;
          if (result.page && result.page.length > 0) {
            this.customerList = result.page;
            this.customerTotalRows = result.total_page;
          }
        }
      });
    },
    selectedCustomerRow(item) {
      this.selectedCustomer.name = item.customer_name;
      this.selectedCustomer.customerId = item.customer_id;
      this.selectedCustomer.siteName = item.site_name;
      this.selectedCustomer.siteId = item.customer_site_id;
      this.showCustomerModal = false;
    },
    openCustomerModal(flag) {
      this.showCustomerModal = flag;
    },
    clearForm() {
      const defaultSelecteValue = {
        value: null,
        text: null
      };
      this.amountFrom = null;
      this.amountTo = null;
      this.bpDate = null;
      this.glDate = null;
      this.billDate = null;
      this.dueDate = null;
      this.selectedBillingCycle = defaultSelecteValue;
      this.selectedBillingStatus = defaultSelecteValue;
      this.selectedFloor = defaultSelecteValue;
      this.selectedPocket = defaultSelecteValue;
      this.selectedProject = defaultSelecteValue;
      this.selectedBillRequest = defaultSelecteValue;
      this.selectedSector = defaultSelecteValue;
      this.selectedSource = defaultSelecteValue;
      this.selectedBillTemplate = defaultSelecteValue;
      this.selectedTower = defaultSelecteValue;
      this.selectedUnit = defaultSelecteValue;
      this.selectedSubUnit = defaultSelecteValue;
      this.currency.id = null;
      this.selectedCustomer.customerId = null;
      this.selectedCustomer.siteId = null;
      this.selectedCustomer.name = null;
      this.selectedCustomer.siteName = null;
      this.documentNumber = null;
    }
  },
  destroyed() {
    this.eventBus.$off('getCurrency');
  }
};
